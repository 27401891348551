$colors: (
  Success: green,
  Ignore: #aaa,
  Warning: yellow,
);

@import "colors.functinons.scss";

@each $name, $color in $colors {
  .icon#{$name} {
    fill: $color;
  }

  .text#{$name} {
    color: $color;
  }

  .bg#{$name} {
    background-color: $color;
    color: choose-contrast-color($color);
  }
}
